import Api from "../../Api";

const END_POINT_ALLFACTURA = 'user/invoice/all';
const END_POINT_VIEWFACTURA = 'user/invoice/view';
const END_POINT_CREATEFACTURA = 'user/invoice/create';
const END_POINT_UPDATEFACTURA = 'user/invoice/update';
const END_POINT_DELETEFACTURA = 'user/invoice/delete';

export default{
  GetAllFactura(data){
    return Api.get(END_POINT_ALLFACTURA, {
      headers: {
        'Authorization': 'Bearer ' + data.access_token
      }
    });
  },

  ViewFactura(id, auth) {
    return Api.get(END_POINT_VIEWFACTURA + '/' + id, {
      headers: {
        'Authorization': 'Bearer ' + auth.access_token
      },
    });
  },

  CreateFactura(data, auth){
    return Api.post(END_POINT_CREATEFACTURA, data, {
      headers: {
        'Authorization': 'Bearer ' + auth.access_token
      }
    });
  },

  UpdateFactura(data,auth){
    return Api.patch(END_POINT_UPDATEFACTURA, data, {
      headers: {
        'Authorization': 'Bearer ' + auth.access_token
      }
    });
  },

  DeleteFactura(id, auth) {
    return Api.delete(END_POINT_DELETEFACTURA + '/' + id, {
      headers: {
        'Authorization': 'Bearer ' + auth.access_token
      },
    });
  }

}